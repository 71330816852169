import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { useApiConfig } from '@upvio/next'
import { ToastGlobalProvider } from 'australis'
import App from 'next/app'
import Head from 'next/head'
import { useState } from 'react'
import { useMount } from '../hooks/useMount'
import { bugsnagStart } from '../utils/bugsnag'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import '../styles/globals.css'
import 'australis/dist/esm/index.css'
import 'australis/dist/esm/foundation/index.css'
import 'australis/dist/esm/foundation/themes.css'

bugsnagStart()

const FormsPortalApp = ({ Component, pageProps }: AppProps) => {
  const config = useApiConfig()
  const [memoizedClient] = useState(
    new ApolloClient({
      uri: `https://api.${config.domain}`,
      cache: new InMemoryCache(),
    }),
  )

  // Initialize upvio namespace
  useMount(() => {
    window.upvio = {}
  })

  return (
    <ApolloProvider client={memoizedClient}>
      <Head>
        <meta name='robots' content='noindex, nofollow' />
        <meta name='description' content={`${config.brandName} Forms`} />
        <link rel='icon' href={`/${config.brand}.favicon.png`} />
      </Head>
      <div className={`theme-${config.brand}`}>
        <Component {...pageProps} />
        <ToastGlobalProvider />
      </div>
    </ApolloProvider>
  )
}

/**
 * Since we're using `router.query` throughout the app, we need to make sure
 * that SSR is triggered for each, even though we don't want to use it.
 */
FormsPortalApp.getInitialProps = async (
  context: AppContext,
): Promise<AppInitialProps> => {
  const ctx = await App.getInitialProps(context)

  return {
    ...ctx,
  }
}

export default FormsPortalApp
